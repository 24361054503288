var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"donations-container"},[_c('b-alert',{attrs:{"variant":_vm.alert.variant,"dismissible":"","fade":""},model:{value:(_vm.alert.model),callback:function ($$v) {_vm.$set(_vm.alert, "model", $$v)},expression:"alert.model"}},[_vm._v(" "+_vm._s(_vm.alert.text)+" ")]),_c('h1',[_vm._v("Donations")]),_c('b-row',{staticClass:"container-search"}),_c('div',{staticClass:"overflow-x-hidden"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-overflow"},[_c('GenericTable',{attrs:{"columns":_vm.columns,"data":_vm.data,"actions":true,"pagination":_vm.pagination,"totalName":_vm.totalName},on:{"updateCurrentPage":_vm.updateCurrentPage,"sort":_vm.sort},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.amount))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === 'ACTIVE')?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Active")]):(row.status === 'INACTIVE')?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("Inactive")]):(row.status === 'PENDING')?_c('b-badge',{attrs:{"variant":"light"}},[_vm._v("Pending")]):(row.status === 'VOID')?_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v("Void")]):(row.status === 'FAILED')?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Error")]):_vm._e()]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(row.status === 'ACTIVE' || row.status === 'PENDING')?_c('VoidDonationModal',{attrs:{"donation":row},on:{"success":_vm.voidSuccess}}):_vm._e()]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }