<template>
  <li
    class="
      relative
      inline-flex
      items-center
      border border-gray-300
      bg-white
      py-2
      -ml-px
      text-sm
      font-medium
      text-gray-500
      focus:z-20
    "
    :class="`${roundedClass} ${iconName ? 'px-2' : 'px-4'} ${interactiveClasses}`"
  >
    <slot>
      <span class="sr-only">{{ screenReaderTitle }}</span>
      <FontAwesomeIcon class="h-3 w-5" aria-hidden="true" :icon="['far', iconName]" v-if="iconName" />
    </slot>
  </li>
</template>

<script>
export default {
  props: {
    screenReaderTitle: String,
    iconName: String,
    roundEdges: {
      type: String,
      validator: (value) => ['left', 'right'].includes(value)
    },
    interactive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    roundedClass() {
      switch (this.roundEdges) {
        case 'left':
          return 'rounded-l-md';
        case 'right':
          return 'rounded-r-md';
        default:
          return '';
      }
    },
    interactiveClasses() {
      return this.interactive ? 'cursor-pointer hover:bg-gray-50' : '';
    }
  }
};
</script>
