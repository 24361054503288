<template>
  <b-container class="donations-container">
    <b-alert v-model="alert.model" :variant="alert.variant" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <h1>Donations</h1>
    <b-row class="container-search">
      <!-- <GenericSearch :value="form.input" @updateSearch="updateSearch" /> -->
    </b-row>
    <div class="overflow-x-hidden">
      <div class="col">
        <div class="table-overflow">
          <GenericTable
            :columns="columns"
            :data="data"
            :actions="true"
            :pagination="pagination"
            :totalName="totalName"
            @updateCurrentPage="updateCurrentPage"
            @sort="sort"
          >
            <template #id="{ row }">
              {{ row.id }}
            </template>
            <template #amount="{ row }">
              <p>{{ row.amount }}</p>
            </template>
            <template #status="{ row }">
              <b-badge variant="success" v-if="row.status === 'ACTIVE'">Active</b-badge>
              <b-badge variant="secondary" v-else-if="row.status === 'INACTIVE'">Inactive</b-badge>
              <b-badge variant="light" v-else-if="row.status === 'PENDING'">Pending</b-badge>
              <b-badge variant="dark" v-else-if="row.status === 'VOID'">Void</b-badge>
              <b-badge variant="danger" v-else-if="row.status === 'FAILED'">Error</b-badge>
            </template>
            <template #actions="{ row }">
              <VoidDonationModal
                v-if="row.status === 'ACTIVE' || row.status === 'PENDING'"
                :donation="row"
                @success="voidSuccess"
              />
            </template>
          </GenericTable>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import DonationserviceV2 from '@/lib/donation-service-v2';
import { isStripePurchaseTimedOut, setStripePurchaseTimedOutError } from '@/lib/stripe-purchase-timeout';
import GenericTable from '@/components/GenericTable.vue';
import VoidDonationModal from '@/components/modals/VoidDonationModal.vue';

export default {
  name: 'Donations',
  components: {
    GenericTable,
    VoidDonationModal
  },
  async mounted() {
    this.getDonations();
  },
  data() {
    return {
      alert: {
        text: '',
        variant: '',
        model: false
      },
      columns: [
        {
          name: 'id',
          label: 'ID',
          classes: 'w-auto'
        },
        {
          name: 'createdAt',
          label: 'Created at',
          classes: 'w-auto'
        },
        {
          name: 'name',
          label: 'Name',
          classes: 'w-auto'
        },
        {
          name: 'email',
          label: 'Email',
          classes: 'w-auto'
        },
        {
          name: 'amount',
          label: 'Amount',
          classes: 'w-auto',
          isCurrency: true
        },
        {
          name: 'status',
          label: 'Status',
          classes: 'w-auto'
        }
      ],
      data: [],
      sortBy: 'createdAt',
      sortDir: 'desc',
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      totalName: 'Donations',
      routeQuery: false,
      canCreateCashSales: false,
      toggleValue: true,
      showDevices: false
    };
  },
  methods: {
    updateSearch(input) {
      this.$store.commit('setSearchInput', input);
    },
    async refreshTable() {
      await this.getDonations();
      this.$root.$emit('bv::refresh::table', 'table');
    },
    async getDonations() {
      try {
        const sessionUser = await getAuth().sessionUser();
        const organizationId = this.isRbAdmin ? undefined : sessionUser.organizationUuid;

        const params = {
          organizationId,
          page: this.currentPage,
          pageSize: this.pageSize,
          sortDir: this.sortDir,
          sortBy: this.sortBy
        };

        const { data, pagination } = await DonationserviceV2.listDonations(params);
        this.pagination = pagination;
        data.forEach((donation) => {
          // if pending and old show as error
          if (isStripePurchaseTimedOut(donation)) {
            setStripePurchaseTimedOutError(donation);
          }
        });
        this.data = data.map((donation) => {
          return {
            ...donation,
            id: this.formatUuid(donation.id),
            createdAt: this.formatDateTime(this.parseISO(donation.createdAt)),
            name: donation.firstName + ' ' + donation.lastName,
            email: donation.email,
            phone: this.formatTelephone(donation.phone),
            amount: this.formatCurrency(donation.amountCents / 100, { precision: 0 }),
            amountCents: donation.amountCents,
            status: donation.status,
            uuid: donation.id,
            currency: donation.currency?.toUpperCase()
          };
        });
      } catch (error) {
        this.handleError(`Failed to load donations: ${error.message}`);
        return [];
      }
    },
    handleError(text) {
      this.alert.text = text;
      this.alert.model = true;
      this.alert.variant = 'danger';
    },
    formatTelephone(val) {
      if (val !== null) {
        return this.formatPhone(val);
      }

      return '';
    },
    voidSuccess() {
      this.refreshTable();
      this.alert.text = 'Donation Voided Successfully!';
      this.alert.model = true;
      this.alert.variant = 'success';
    },
    async updateCurrentPage(page) {
      this.currentPage = page;
      await this.getDonations();
    },
    async sort(sortBy, sortDir) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      await this.getDonations();
    }
  }
};
</script>

<style scoped>
.min-search-width {
  min-width: 20rem;
}
.donations-container {
  text-align: left;
  max-width: 90%;
}
.container-search {
  padding-bottom: 1rem;
  padding-left: 2rem;
}
.container-void {
  padding-top: 1rem;
}

.dashline {
  border-bottom: 1px dashed;
}

.action-button {
  display: flex;
  align-items: center;
  padding-right: 2rem;
}

@media screen and (max-width: 62em) {
  .donations-container {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
}
</style>
