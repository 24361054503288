<template>
  <GenericModal :title="title" size="sm" :config="modalConfig" @modal-closed="modalClosed" :errorMessage="errorMessage">
    <template #openButton="{ openModal }">
      <RbButton iconVariant="faBan" variant="secondary-outline" @click.native="openModal" :disabled="disabled">
        Void
      </RbButton>
    </template>
    <template #header>
      <div class="px-2 text-lg font-bold">Void Donation</div>
    </template>
    <template>
      <div class="mb-4">Are you sure you wish to void this donation?</div>
      <hr class="border-gray-300" />
      <div class="p-2 mt-2 mb-2">
        <div class="w-full">
          <div class="flex flex-row justify-between">
            <p class="font-bold inline">Donation #{{ formatUuid(donation.uuid) }}</p>
            <p class="font-bold pr-2">
              {{ formatCurrency(donation.amountCents / 100, { precision: 2, currency: donation.currency }) }}
            </p>
          </div>
        </div>
      </div>
      <hr class="border-gray-300" />
    </template>
    <template>
      <div class="pt-4">
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group
            label-for="voided-comment"
            :invalid-feedback="veeErrors.first('voided-comment')"
            label-class="mb-0"
          >
            <template slot="label">Reason for Voiding: <span class="text-red-600 required">*</span></template>
            <b-form-input
              name="voided-comment"
              v-model="comment"
              v-validate="{ required: true, max: 120 }"
              :state="validateState('voided-comment')"
              aria-describedby="voided-comment-feedback"
              data-vv-as="comment"
              trim
            />
          </b-form-group>
        </b-form>
      </div>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <div class="flex py-1">
        <RbButton variant="secondary" @click.native="closeModalHandler(closeModal)" class="mr-2"> Cancel </RbButton>
        <RbButton variant="danger" :disabled="!comment" :loading="loading" @click.native="submit(closeModal)">
          Void
        </RbButton>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import DonationServiceV2 from '@/lib/donation-service-v2';

export default {
  props: {
    donation: {
      type: Object
    },
    disabled: {
      type: Boolean
    }
  },
  components: {
    GenericModal
  },
  data() {
    return {
      modalConfig: {
        showHeaderClose: true,
        showCloseModalButton: false
      },
      errorMessage: null,
      loading: false,
      comment: '',
      title: 'Void Donation',
      voidDescription: 'Are you sure you wish to void this donation?',
      voidSuccessfulMessage: 'Donation voided successfully'
    };
  },
  methods: {
    async submit(closeModal) {
      this.loading = true;
      try {
        await DonationServiceV2.voidDonation(this.donation.uuid, this.comment);

        this.loading = false;
        closeModal();

        this.$emit('success');
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
        this.loading = false;
      }
    },
    closeModalHandler(closeModal) {
      closeModal();
    },
    modalClosed() {
      this.errorMessage = null;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>
