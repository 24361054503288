import Axios from '@/axios';

const donationsUrl = `/donation-service/v2/donations`;

const listDonations = async (params = {}) => {
  const response = await Axios.get(donationsUrl, {
    params
  });

  return response.data.data;
};

const retrieveDonation = async (donationId) => {
  const response = await Axios.get(`${donationsUrl}/${donationId}`);

  return response.data.data.data;
};

const voidDonation = async (donationId, voidedComment) => {
  try {
    const response = await Axios.post(`${donationsUrl}/${donationId}/void`, {
      donationId,
      voidedComment
    });

    return response.data;
  } catch (error) {
    console.log(`Error: ${error}`);
    throw error;
  }
};

export default {
  listDonations,
  retrieveDonation,
  voidDonation
};
